<template>
  <main id="PurchaseAgreement">
    <div class="row">
      <article>
        <header>
          <h2>購物須知</h2>
        </header>
        <div class="content">
          <ol>
            <li>
              <p>一、購物流程</p>
              <ol>
                <li>
                  （1）進入開始使用->填寫個人資料->選擇方案->購物結帳->信箱取得服務以及教學。
                </li>
                <li>（2） 訂單工作天將在訂單無誤之後3-5天工作天製作完成。</li>
              </ol>
            </li>
            <li>
              <p>
                二、付款方式
                <ol>
                  <li>信用卡付款</li>
                  <li>免手續費</li>
                  <li>金流服務平台：藍新金流 NewebPay</li>
                </ol>
              </p>
            </li>
            <li>
              <p>
                三、訂單資訊
                <ol>
                  <li>訂單成立後，系統會發送e-mail通知至下單時填寫之信箱，亦會收到e-mail通知。</li>
                  <li>因個人因素多次取消服務訂閱(一年三次為限)，該名消費者會加入黑名單，將無法再次訂閱服務。</li>
                </ol>
              </p>
            </li>
            <li>
              <p>
                四、退換貨/解約
                <ol>
                  <li>根據『通訊交易解除權合理例外情事適用準則』明訂，非以有形媒介提供之数位內容或一經提供即為完成之線上服務,經消費者事先同意始提供，可不適用7天鑑賞期規範。如有任何問題皆以官方line為主詢問或諮詢。</li>
                  <li>因有綁信用卡續約因素，如果需要解約，需於當月信用卡結帳日前五個工作天在官方line提供資料以及確認解約，若超過當月信用卡結帳日前五個工作天將順延下月取消。</li>
                  <li>本公司得視業務需要及實際情形，增減、變更或終止相關服務之項目或內容。</li>
                  <li>相關購物折扣與優惠以成功付款之訂單為準，若延遲付款造成之訂單取消，需採用重新下單時之服務內容。</li>
                </ol>
              </p>
            </li>
          </ol>
        </div>
      </article>
    </div>
    <Footer/>
  </main>
</template>

<script>
import SmoothScrollResister from "@/common/GSAP/SmoothScrollResister.js"
import Footer from "@/components/MainFooter"
import {GetMetaData} from "@/common/meta.js"
export default {
  name: "PurchaseAgreement",
  mixins: [SmoothScrollResister],
  components: {
    Footer
  },
  data() {
    return {
      meta_data:null
    }
  },
  methods: {},
  mounted() {
    this.RegisterGsap("PurchaseAgreement")
          window.prerenderReady = true;
  },
  created() {
    this.meta_data = GetMetaData('購物須知', '', '');
  },
  metaInfo() {
    return this.meta_data;
  },
}
</script>